<template>
  <!-- Error page-->
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        to="/"
      >
        <!-- <vuexy-logo /> -->
        <custom-logo />
        <h2 class="brand-text text-primary ml-1 mr-2">
          {{ navbarTitle }}
        </h2>
        <b-dropdown
          :text="getUserData != null ? `${getUserData.firstName} ${getUserData.lastName}` : ''"
          variant="gradient-primary"
          class="mr-2"
        >
          <b-dropdown-item @click="logout">
            {{ $t("Logout") }}
          </b-dropdown-item>
        </b-dropdown>
        <router-link
          ref="redirect"
          :to="'/select-profile/add'"
        >
          <b-button
            v-if="getUserData !== null && getUserData.applicationId !== null"
            type="submit"
            variant="success"
            block
          >{{ $t("Add new profile") }}</b-button></router-link>
        <dark-Toggler class="d-none d-lg-block" />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="p-5"
      >
        <div>
          <b-row class="mt-5">
            <b-col
              v-for="(item, index) in profilePartner"
              :key="index"
              cols="4"
            >
              <b-overlay
                :show="isLoading"
                rounded="sm"
              >
                <b-card
                  class="text-center"
                >
                  <b-card-header class="p-1">
                    <b-card-title class="font-medium-2">
                      <h3>{{ (index+1) }}. {{ item.firstName.toString() }}</h3>
                    </b-card-title>
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="deleteProfile(item)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                      />
                    </b-button>
                  </b-card-header>
                  <b-img
                    v-if="item.image"
                    ref="previewEl"
                    rounded
                    :src="item.image"
                    height="100"
                  />
                  <b-img
                    v-else
                    ref="previewEl"
                    :src="require('@/assets/images/blank/no_image.png')"
                    rounded
                    height="100"
                  />
                  <p v-if="item.typeProfile !== null">
                    {{ $t(item.typeProfile.replace('_',' ').toUpperCase()) }}
                  </p>
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        class="mt-2"
                        variant="primary"
                        @click="selectProfile(item)"
                      >
                        <span class="mr-25 align-middle"> {{ $t("SELECT") }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="6">
                      <b-button
                        class="mt-2"
                        variant="warning"
                        @click="editProfile(item)"
                      >
                        <span class="mr-25 align-middle"> {{ $t("Edit") }}</span>
                      </b-button>
                    </b-col>
                  </b-row>

                </b-card>
              </b-overlay>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <!-- /Left Text-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-img
          fluid
          :src="imgUrl"
          alt="Login V2"
        />

      </b-col>
    </b-row>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton,
  BImg,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BAvatar,
//   BDropdownDivider,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'

import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, storeGroceryAbility, storeFoodAbility, storeQueueAbility, driverDeliveryAbility, driverCallAbility, servicesAbility,
} from '@/libs/acl/config'
import storeModule from '@/store/SelectProfile/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

const STORE_MODULE_NAME = 'selectProfile'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    DarkToggler,
    CustomLogo,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BImg,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // BDropdownDivider,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      isLoading: false,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    profilePartner() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    themeConfig() {
      return $themeConfig
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }

    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        console.log('fetch success :', result)
        if (this.$route.query.id) {
          const profileData = this.profilePartner.filter(p => p._id === this.$route.query.id)
          if (profileData.length > 0) {
            this.selectProfile(profileData[0])
          }
        }
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    localStorage.removeItem('profilePartnerId')
    localStorage.removeItem('profilePartnerData')
    localStorage.removeItem('ability')
    localStorage.removeItem('menuService')
    // Reset ability
    this.$ability.update(initialAbility)

    store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    store.commit('appConfig/RESET_NAVBAR_TITLE')
    store.commit('appConfig/RESET_NAVBAR_IMAGE')
  },
  methods: {
    showToast(variant, position, title, icon) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    addProfile() {
      this.$router.replace({ name: 'select-profile-view', params: { id: 'add' } }).catch(e => { console.log(e) })
      // this.$router.push({ path: '/select-profile/add' })
    },
    editProfile(data) {
      this.$router.push({ name: 'select-profile-view', params: { id: data._id } }).catch()
    },
    deleteProfile(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete profile')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          this.show = false

          if (value === true) {
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, data._id)
              .then(result => {
                console.log('fetch success :', result)
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    selectProfile(data) {
      console.log(data)
      localStorage.setItem('profilePartnerId', data._id)
      localStorage.setItem('profilePartnerData', JSON.stringify(data))
      localStorage.setItem('menuService', data.typeProfile.replace('_', '-'))
      store.commit('appConfig/UPDATE_NAVBAR_TITLE', `${data.firstName} ${data.lastName}`)
      if (data.image) {
        store.commit('appConfig/UPDATE_NAVBAR_IMAGE', `${data.image}`)
      }
      store.commit('appConfig/UPDATE_MENU_SERVICE', data.typeProfile.replace('_', '-'))
      if (data.typeProfile === 'store_grocery') {
        this.$router.replace({ name: 'store-grocery-dashboard' })
        this.$ability.update(storeGroceryAbility())
        localStorage.setItem('ability', JSON.stringify(storeGroceryAbility()))
      } else if (data.typeProfile === 'store_food') {
        this.$router.replace({ name: 'store-food-dashboard' })
        this.$ability.update(storeFoodAbility())
        localStorage.setItem('ability', JSON.stringify(storeFoodAbility()))
      } else if (data.typeProfile === 'store_queue') {
        this.$router.replace({ name: 'store-queue-dashboard' })
        this.$ability.update(storeQueueAbility())
        localStorage.setItem('ability', JSON.stringify(storeQueueAbility()))
      } else if (data.typeProfile === 'driver_delivery') {
        this.$router.replace({ name: 'driver-delivery-dashboard' })
        this.$ability.update(driverDeliveryAbility())
        localStorage.setItem('ability', JSON.stringify(driverDeliveryAbility()))
      } else if (data.typeProfile === 'driver_call') {
        this.$router.replace({ name: 'driver-call-dashboard' })
        this.$ability.update(driverCallAbility())
        localStorage.setItem('ability', JSON.stringify(driverCallAbility()))
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      useJwt.logout()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace({ name: 'auth-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
      store.commit('appConfig/RESET_NAVBAR_TITLE')
      store.commit('appConfig/RESET_NAVBAR_IMAGE')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/pages/page-auth.scss";

</style>
